import React, { useState, useRef, useEffect } from 'react'
import { useStaticQuery, graphql } from "gatsby"

import Category from './category'

import { motion, useInvertedScale, AnimatePresence } from 'framer-motion'
import AnimWrapper from './animWrapper'
import BigLink from './bigLink'
import { TransitionLink } from 'gatsby-plugin-transition-link/components/TransitionLink'


import createSrc from '../helper/sourceSet'

const variants = {
    visible: {
        opacity: 1,
        y: "0px",
        scale: 1,
        transition: {
            duration: 0.2,
            when: "beforeChildren",
            staggerChildren: 0.1,
        },
    },
    hidden: {
        opacity: 0,
        y: "24px",
        scale: 1.05,
        transition: {
            when: "afterChildren",
        },
    },
    exit: {
        opacity: 0,
        y: "-24px",
        scale: 0.95,
        transition: {
            when: "afterChildren",
            duration: 0.1,
            staggerChildren: 0.05,
        },
    },
}



const WorksOverview = (props) => {
    let featured = false
    if (props.blok) featured = props.blok.featured_only


    const data = useStaticQuery(
        graphql`
            query WorksOverviewQuery {
                allStoryblokEntry(
                    filter: {
                        field_component: {eq: "workEntry"},
                    },
                    sort: {fields: position, order: ASC}
                ){
                    edges {
                        node {
                            name
                            full_slug
                            slug
                            content
                            field_info_string
                            field_title_string
                            field_description_string
                            field_thumbnail_string
                            field_date_string
                            field_is_featured_boolean  
                        }
                    }
                }
            }
        `
    )
    const entries = data.allStoryblokEntry.edges

    const [clickedElement, setClickedElement] = useState()
    const [bounds, setBounds] = useState({
        s: 2.05,
        x: 0,
        y: 0
    })

    const grid = useRef()


    const entryRefs = useRef([])


    const [filter, setFilter] = useState("")
    const [open, setOpen] = useState(false)


    useEffect(() => {
        entryRefs.current = entryRefs.current.slice(0, entries.length);
    }, [entries]);

    const scroll = () => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }

    const workVariants = {
        visible: {
            opacity: 1,
        },
        hidden: {
            opacity: 0,
        },
        exit: {
            opacity: 1,
            originY: 0,
            x: bounds.x,
            y: bounds.y,
            scale: bounds.s,
            zIndex: 1000,
            transition: {
                delay: 0.05,
                duration: 0.45,
                ease: "easeInOut"
            }
        },
    }

    const quickFade = {
        hidden: { opacity: 0 },
        visible: { opacity: 1 },
        exit: {
            opacity: 0,
            transition: {
                duration: 0.25,
                ease: "easeInOut"
            }
        }
    }

    const fade = {
        hidden: { opacity: 0 },
        visible: { opacity: 1 },
        exit: { opacity: 0 }
    }

    const noMotion = {
        hidden: { opacity: 1 },
        visible: { opacity: 1 },
        exit: { opacity: 1 }
    }

    const blockScale = {
        hidden: { scale: 1 },
        visible: { scale: 1.05 },
        exit: { scale: 1 }
    }

    const text = {
        hidden: { fontSize: 16 },
        visible: { fontSize: 16 },
        exit: {
            fontSize: 48,
            transition: {

                duration: 0.5,
                ease: "easeInOut"
            }
        }
    }

    return (
        <>
            <motion.header variants={fade} className="works-header">
                {featured ? <>
                    <h2>Selected Works</h2>
                    <BigLink className="hideMobile" to={'/works/'}> show all works </BigLink>
                </> : <>
                        <h2 className="showMobile">All Works:</h2>
                        <h2 className="hideMobile">Filter Works:</h2>
                        <ul className={open ? "open hideMobile" : "closed hideMobile"}>
                            <li className={filter === "" ? "active" : ""} key={"All"}
                                onClick={() => {
                                    if (open) {
                                        setFilter("")
                                        setOpen(false)
                                    } else {
                                        setOpen(true)
                                    }
                                }
                                }  ><span>All</span>
                            </li>
                            {["ux", "frontend", "motion", "graphics", "illustration"].map(cat => (
                                <li className={filter === cat ? "active" : ""} key={cat}>
                                    <Category category={cat} textOnly={true}
                                        onClick={() => {
                                            if (open) {
                                                setFilter(cat)
                                                setOpen(false)
                                            } else {
                                                setOpen(true)
                                            }
                                        }
                                        }
                                    />
                                </li>
                            ))}
                        </ul>
                    </>}
            </motion.header>

            <AnimatePresence exitBeforeEnter>
                <AnimWrapper
                    variants={variants}
                    initial="hidden"
                    animate="visible"
                    exit="exit"
                >
                    <div ref={grid}
                        className={featured ? "featured grid" : "grid"}>

                        {entries.map((entry, index) => (

                            ((entry.node.field_is_featured_boolean && featured) || !featured) && (filter === "" || JSON.parse(entry.node.content).categories.includes(filter)) && (
                                <motion.div
                                    variants={clickedElement === index ? workVariants : (clickedElement !== undefined ? quickFade : variants)}
                                    key={index + filter}
                                    custom={index}
                                    ref={el => entryRefs.current[index] = el}
                                >
                                    <TransitionLink
                                        exit={{
                                            length: 0.5,
                                            trigger: () => {
                                                //scroll()
                                                setClickedElement(index)
                                                let gridBound = grid.current.getBoundingClientRect()
                                                let currentBound = entryRefs.current[index].getBoundingClientRect()

                                                setBounds({
                                                    s: gridBound.width / currentBound.width,
                                                    y: gridBound.y - currentBound.y + window.pageYOffset,
                                                    x: gridBound.width != currentBound.width ? gridBound.x - currentBound.x + currentBound.width / 2 + 12 : 0
                                                })
                                            }
                                        }}

                                        entry={{ 
                                            appearAfter: 0.1, 
                                            delay: 0.4, 
                                            state: {type: "outOnly"}
                                        }}
                                        className="work-entry"
                                        to={"/" + entry.node.full_slug}>
                                        <div className="work-entry-thumbnail">
                                            <motion.img 
                                                variants={clickedElement === index ? blockScale : noMotion}
                                                alt={entry.node.field_title_string}
                                                { ...createSrc( entry.node.field_thumbnail_string ) }
                                                />
                                        </div>
                                        <motion.div variants={fade} className="work-entry-info">
                                            <div className="work-entry-title">
                                                {entry.node.field_title_string}
                                            </div>
                                            <div className="work-entry-categories">
                                                <ul>
                                                    {JSON.parse(entry.node.content).categories.map(cat => (
                                                        <li key={cat}>
                                                            <Category category={cat} />
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </motion.div>
                                    </TransitionLink>
                                </motion.div>
                            )

                        ))}

                    </div>
                </AnimWrapper>
            </AnimatePresence>
            {featured  && <div className="showMobile" style={{display:"flex", justifyContent: "center", marginTop: "2rem"}}><BigLink  to={'/works/'}> show all works </BigLink></div>}

        </>

    )
}


/*
export const query = */
export default WorksOverview