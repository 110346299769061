const generateSourceString = (baseURL, fileName, widths) => {

    let output = ""

    if(!Array.isArray(widths)){
        //output += `https://via.placeholder.com/${widths}x${Math.floor(widths / 19 * 10)}`
        output += `${baseURL}${widths}/${fileName}`
    }else{
        widths.forEach((width, index) => {
            //output += `https://via.placeholder.com/${width}x${Math.floor(width / 19 * 10)} ${width}w`
            output += `${baseURL}${width}/${fileName} ${width}w`
            if(index < widths.length -1) output += ","
        });
    }
    
    return output
}


const createSrc = (src) => {

    const sizes = [320, 476, 663, 976]
    const base = src ? "https://res.cloudinary.com/altreiter/image/upload/f_auto,q_auto:good,c_fill,ar_19:10,w_" : "https://placekitten.com/1152/604"
    const regex = /v[0-9]*\/(.*)/g
    let file = src.match(regex)

    return {
        sizes: `(min-width: 860px) 476px,  
                (min-width: 696px) 320px,
                (min-width: 569px) 663px,
                (min-width: 398px) 476px,
                320px`,
        srcSet: generateSourceString(base, file, sizes),
        src: generateSourceString(base, file, sizes[2])
    }
    
}

export default createSrc