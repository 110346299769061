import React from 'react'
import AnimWrapper from './animWrapper'
import { motion } from 'framer-motion';
import BigLink from './bigLink';

const variants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1},
    exit: { opacity: 0 }
  }

const AboutTeaser = (props) => (
    <AnimWrapper variants={variants}>
      
      <div className="about-teaser">
        <h2> Me, Myself & I </h2>  
        <div className="about-teaser-content">
        <motion.img alt="avatar" className="avatar" src="https://res.cloudinary.com/altreiter/image/upload/c_scale,q_auto:good,w_320/v1590314446/about/bewerbungsbild-small_rm1ief.jpg"/>
        <div>
          <p>
            In short, My name is David, I'm 27 years old and I'm a UX Designer based in Linz, Austria. To learn more about me, click the link below!
          </p>
          <BigLink to={"/about/"}> about me </BigLink>
        </div>
        </div>
      </div>
    </AnimWrapper>
)

export default AboutTeaser





    
